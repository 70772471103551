import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Base from "@/components/base/base.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Base,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      // users rotues
      {
        path: "users",
        name: "users",
        component: () => import('@/views/users/index.vue'),
      },
      
      {
        path: "users/create",
        name: "users-create",
        component: () => import('@/views/users/create.vue'),
      },
      {
        path: "users/car/:id",
        name: "cars-create",
        component: () => import('@/views/users/cars/create.vue'),
      },
      {
        path: "users/tickets/:id",
        name: "tickets-create",
        component: () => import('@/views/users/tickets/create.vue'),
      },
      {
        path: "users/edit/:id",
        name: "users-edit",
        component: () => import('@/views/users/edit.vue'),
      },
      {
        path: "users/view/:id",
        name: "users-view",
        component: () => import('@/views/users/view.vue'),
      },

      // consultutns rotues
      {
        path: "consultunts",
        name: "consultunts",
        component: () => import('@/views/consultunts/index.vue'),
      },
      {
        path: "consultunts/edit/:id",
        name: "consultunts-edit",
        component: () => import('@/views/consultunts/edit.vue'),
      },
      {
        path: "consultunts/create",
        name: "consultunts-create",
        component: () => import('@/views/consultunts/create.vue'),
      },
       // services rotues
       {
        path: "services",
        name: "services",
        component: () => import('@/views/services/index.vue'),
      },
      {
        path: "services/edit/:id",
        name: "services-edit",
        component: () => import('@/views/services/edit.vue'),
      },
      {
        path: "services/create",
        name: "services-create",
        component: () => import('@/views/services/create.vue'),
      },
      // brands rotues
      {
        path: "brands",
        name: "brands",
        component: () => import('@/views/brands/index.vue'),
      },
      {
        path: "brands/edit/:id",
        name: "brands-edit",
        component: () => import('@/views/brands/edit.vue'),
      },
      {
        path: "brands/create",
        name: "brands-create",
        component: () => import('@/views/brands/create.vue'),
      },
       // centers rotues
       {
        path: "centers",
        name: "centers",
        component: () => import('@/views/centers/index.vue'),
      },
      {
        path: "centers/edit/:id",
        name: "centers-edit",
        component: () => import('@/views/centers/edit.vue'),
      },
      {
        path: "centers/create",
        name: "centers-create",
        component: () => import('@/views/centers/create.vue'),
      },

       // classifications rotues
       {
        path: "classifications",
        name: "classifications",
        component: () => import('@/views/classifications/index.vue'),
      },
      {
        path: "classifications/edit/:id",
        name: "classifications-edit",
        component: () => import('@/views/classifications/edit.vue'),
      },
      {
        path: "classifications/create",
        name: "classifications-create",
        component: () => import('@/views/classifications/create.vue'),
      },

         // types rotues
         {
          path: "types",
          name: "types",
          component: () => import('@/views/types/index.vue'),
        },
        {
          path: "types/edit/:id",
          name: "types-edit",
          component: () => import('@/views/types/edit.vue'),
        },
        {
          path: "types/create",
          name: "types-create",
          component: () => import('@/views/types/create.vue'),
        },
    ]
  },
  {
    path: '/server-error',
    component: () => import('@/views/errors/server.vue'),
  },
  {
    path: '/login',
    component:Login
  }
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
