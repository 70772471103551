export default [
  {
    icon: "mdi-account-outline",
    title: "sidebar.users",
    children: [
      {
        icon: "mdi-account-outline",
        title: "sidebar.users",
        to: `/users`,
      }
    ]
  },
  {
    icon: "mdi-cog-outline",
    title: "sidebar.general",
    children: [
      {
        icon: "mdi-account-wrench-outline",
        title: "sidebar.services",
        to: `/services`,
      },
      {
        icon: "mdi-account-wrench-outline",
        title: "sidebar.centers",
        to: `/centers`,
      },
      {
        icon: "mdi-account-wrench-outline",
        title: "sidebar.types",
        to: `/types`,
      },
      {
        icon: "mdi-account-wrench-outline",
        title: "sidebar.classifications",
        to: `/classifications`,
      },
      {
        icon: "mdi-account-wrench-outline",
        title: "sidebar.brands",
        to: `/brands`,
      }
    ]
  },
  
];
