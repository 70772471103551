
export const required = (val:string , msg:string = 'هذا الحقل مطلوب') => !!val || msg
export const email = (val:string , msg:string = 'تحقق من البريد الالكتروني') => {
    if (!val){
        return true
    }
    if(!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(val))) return msg


    return true
}

export const updateUserValidation = {
    Name :[
        (value:string) => required(value) ,
    ],
        Email :[
        (value:string) => email(value , "تحقق من البريد اللاكتروني")
    ],
    Phone :[
        (value:string) => required(value) ,
    ],

}


export const carValidation = {
    Type :[
        (value:string) => required(value) ,
    ],
    Model :[
        (value:string) => required(value) ,
    ],
    Mileage :[
        (value:string) => required(value) ,
    ],

}

export const loginValidation = {
    Username :[
        (value:string) => email(value , "تحقق من البريد اللاكتروني")
    ],
    
    Password :[
        (value:string) => required(value) ,
    ],

}
export const CreateUpdateConsultuntsValidation = {
    Name :[
        (value:string) => required(value)
    ],
    Title :[
        (value:string) => required(value)
    ],
    
    Skills :[
        (value:string) => required(value) ,
    ],
    
    
    Breif :[
        (value:string) => required(value) ,
    ],

}


export const CreateUpdateServiceValidation = {
    Name :[
        (value:string) => required(value)
    ],
    Icon :[
        (value:string) => required(value)
    ],
}


